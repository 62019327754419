import React from 'react'

function RatedM(props) {
  return (
    <svg width={40} height={61} viewBox="0 0 40 61" {...props}>
      <title>{'Rated-M'}</title>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFFFFE" d="M.07 60.232h39.684V.705H.07z" />
        <path fill="#1A1919" d="M.647 59.595h38.531V1.343H.647z" />
        <path
          fill="#FFFFFE"
          d="M2.586 13.074h34.376v-9.66H2.586zM2.57 50.689h34.396v-35.51H2.57zM2.577 57.496v-4.715h6.905v1.103H5.351v.674h3.497v1.1H5.351v.738h4.292v1.1H2.577M15.51 54.154c-.043-.158-.198-.262-.401-.319a2.514 2.514 0 00-.716-.087c-.61 0-.883.099-.883.275 0 .697 4.869.26 4.869 1.975 0 1.091-1.637 1.625-3.963 1.625-2.234 0-3.691-.727-3.75-1.579h2.656c.06.17.225.291.461.371.223.079.514.12.789.12.694 0 1.155-.123 1.155-.346 0-.715-4.873-.225-4.873-2.022 0-1.002 1.553-1.503 3.67-1.503 2.35 0 3.421.666 3.624 1.49H15.51M22.533 55.853v1.643h-2.774v-4.715h3.999c2.818 0 3.749.431 3.749 1.347 0 .545-.434.998-1.422 1.157.893.179 1.435.291 1.435 1.242 0 .613-.037.826.293.826v.143H25c-.069-.106-.139-.407-.139-.825 0-.619-.188-.818-1.481-.818h-.847zm0-1.027h1.091c.858 0 1.319-.125 1.319-.45 0-.332-.469-.492-1.117-.492h-1.293v.942zM29.212 57.496v-4.715h4.047c1.906 0 3.423.256 3.423 1.189 0 .486-.567.84-1.106 1.024.916.132 1.407.574 1.407 1.08 0 1.05-1.442 1.422-3.645 1.422h-4.126zm2.772-1.969v.869h1.211c.688 0 1.203-.08 1.203-.432 0-.412-.644-.437-1.531-.437h-.883zm0-1.643v.789h1.157c.552 0 .954-.142.954-.412 0-.369-.547-.377-1.13-.377h-.981z"
        />
        <path
          fill="#1A1919"
          d="M3.817 23.972l10.548-2.842 6.016 14.166.051-.017-1.816-15.287 10.548-2.839 6.581 24.737-6.428 1.723-4.707-17.684-.05.02 1.993 18.406-6.957 1.877-7.417-16.949-.053.013 4.698 17.684-6.425 1.726-6.582-24.734M3.54 10.968v-5.47h1.553l.723 3.719h.013l.766-3.719h1.503v5.47h-.94v-4.22h-.012l-.889 4.22h-.92l-.845-4.22H4.48v4.22h-.94M10.393 6.551h-.015l-.44 2.364h.921l-.466-2.364zm-1.86 4.417l1.278-5.47h1.272l1.252 5.47h-1.016l-.255-1.213H9.746l-.261 1.213h-.952zM15.153 5.498v.91H14.08v4.56h-.966v-4.56h-1.073v-.91h3.112M18.721 5.498v3.878c0 .207-.027.412-.08.612a1.58 1.58 0 01-.262.537 1.355 1.355 0 01-.483.387c-.2.097-.449.146-.742.146-.531 0-.927-.15-1.185-.454-.258-.302-.387-.712-.387-1.228V5.498h.965v3.803c0 .339.055.579.163.724a.52.52 0 00.432.216c.212 0 .369-.081.465-.242.1-.163.149-.394.149-.698V5.498h.965M20.452 7.862h.429a.373.373 0 00.237-.075.586.586 0 00.152-.194.837.837 0 00.082-.262 1.88 1.88 0 00.022-.273c0-.262-.044-.447-.132-.557a.443.443 0 00-.361-.162h-.429v1.523zm0 3.106h-.966v-5.47h1.784c.333 0 .595.123.791.365.192.241.291.597.291 1.066 0 .35-.067.645-.197.887s-.349.394-.66.455v.016c.051.009.123.025.218.044.093.02.185.066.277.137a.874.874 0 01.239.315c.07.138.103.332.103.578a19.307 19.307 0 00.02.849c.008.166.019.321.035.461.014.143.032.241.053.297h-.945a1.472 1.472 0 01-.099-.523 32.608 32.608 0 01-.004-.537c0-.162-.001-.317-.003-.462a1.13 1.13 0 00-.063-.384.542.542 0 00-.205-.261c-.096-.065-.236-.099-.418-.099h-.251v2.266zM23.061 10.968v-5.47h2.517v.841h-1.552v1.35h1.431v.84h-1.431v1.597h1.603v.842h-2.568M27.587 6.32c.161-.111.316-.234.467-.371.148-.136.279-.296.394-.481h.857v5.5h-.966V6.737a3.23 3.23 0 01-.362.332 8.773 8.773 0 01-.39.283V6.32M32.706 6.517l-1.088 4.451h-1.003l1.222-4.648h-1.529v-.852h2.398v1.049M34.194 6.559h.711v1.493h1.079v.84h-1.079v1.492h-.711V8.892h-1.087v-.84h1.087V6.559"
        />
      </g>
    </svg>
  )
}

export default RatedM
