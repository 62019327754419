import React from 'react'
import styled from '@emotion/styled'

const Button = styled.button`
  font-size: 1.8rem;
  color: #41c8c7;
  font-weight: 700;
  font-family: Avenir Bold, 'Arial Narrow Bold', sans-serif;
  text-transform: uppercase;
  padding: 12px 22px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  background-color: rgba(27, 68, 67, 0.5);
  border: 2px solid #3ba4c3;
  cursor: pointer;
  transition: all 300ms ease-in;
  display: inline-block;

  &:hover {
    color: #dc394c;
    border: 2px solid #dc394c;
    transform: translate3d(0.5px, -1px, 0);
  }
`

export default Button
