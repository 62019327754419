import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Root = styled.textarea`
  box-sizing: border-box;
  border: 0;
  padding: 20px;  
  width: ${props => props.full && '100%'};
  margin-top: ${props => props.full && '5px'};
  margin-bottom: ${props => props.full && '5px'};
  border-radius: 10px;

  &::placeholder {
    font-style: italic;
  }  
`

class TextArea extends React.Component {
  render() {
    const value = this.context.formState[this.props.name]
    return (
      <Root {...this.props} value={value || ''} onChange={this.context.updateFormState}>
        {this.props.children}
      </Root>
    )
  }
}

TextArea.contextTypes = {
  updateFormState: PropTypes.func,
  formState: PropTypes.object,
}

export default TextArea