import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Carousel, Slide, Dot } from './Carousel'
import CarouselVideoPlayer from './CarouselVideoPlayer'
import useAsyncEffect from 'use-async-effect'
import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import parseInt from 'lodash/fp/parseInt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { H3 } from './Headings'

const videoIds = ['nYM0pR2Kyik', 'D5bji9TiA7g', 'XbkQwrhsxCw', 'uRE1OhXAgx4', '4vX1dQmNWGo', 'a2iOaxh5eLA']

const API_KEY = process.env.GATSBY_YT_API_KEY

async function fetchStatistics() {
  try {
    const res = await fetch(`${process.env.GATSBY_YOUTUBE_ENDPOINT}?videoIds=${videoIds.join(',')}`)
    if (!res.ok) throw new Error(res.statusText)
    return res.json()
  } catch (error) {
    console.log('Error fetching statistics from Youtube: ', error)
  }
}

function YoutubeCarousel() {
  const [videos, setVideos] = useState([])
  const [carouselActive, setActive] = useState(true)

  useAsyncEffect(async () => {
    const response = await fetchStatistics()
    if (response && response.items) {
      setVideos(response.items)
    }
  }, [])

  if (!videos || videos.length < 1) return null

  return (
    <Carousel
      className="youtube-carousel"
      dots={
        <CarouselFooter totalSlides={videos.length}>
          {videos.map((video, index) => (
            <VideoThumbnail slide={index} key={video.id}>
              <img
                src={get('snippet.thumbnails.medium.url', video)}
                width={get('snippet.thumbnails.medium.width', video)}
                height={get('snippet.thumbnails.medium.height', video)}
                style={{ maxWidth: '100%', height: 'auto', display: 'block' }}
              />
              <YtIcon icon={faYoutube} color="white" size="2x" />
            </VideoThumbnail>
          ))}
        </CarouselFooter>
      }
      active={carouselActive}
      totalSlides={videos.length}
    >
      {videos.map((video, index) => (
        <Slide index={index} key={video.id}>
          {carouselActive && (
            <Overlay>
              <H3 style={{ margin: 0 }}>Meet Our Influencers</H3>
              <p>{pipe(get('statistics.viewCount'), parseInt(10))(video).toLocaleString()} Views</p>
              <FontAwesomeIcon icon={faYoutube} color="white" size="2x" style={{ opacity: 0.65 }} />
            </Overlay>
          )}
          <CarouselVideoPlayer
            url={`https://youtu.be/${video.id}`}
            onPlay={() => setActive(false)}
            onPause={() => setActive(true)}
          />
          )}
        </Slide>
      ))}
    </Carousel>
  )
}

const YtIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  opacity: 0.65;
`

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4rem;

  @media (min-width: 768px) {
    justify-content: center;
    padding-top: 0;
  }
`

const CarouselFooter = styled.ul`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: repeat(${props => props.totalSlides}, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  padding: 1rem 2rem;
  z-index: 1;
  transition: opacity 300ms ease-in;
  background-color: rgba(0, 0, 0, 0.75);
  margin: 0;

  @media (min-width: 768px) {
    padding: 3rem 6rem;
  }

  & button:disabled {
    border: 1px solid #ed455e;
    background-color: #ed455e;
  }
`

const VideoThumbnail = styled(Dot)`
  position: relative;
  border-radius: 0;
  border: 1px solid white;
  padding: 0;
`

const ThumbPlaceholder = styled.div`
  height: 103px;
  background-color: #d8d8d8;
`

export default YoutubeCarousel
