import React from 'react'
import styled from '@emotion/styled'

function CopySection () {
  return (
    <CopyArea>
      <CopyH1>We are story play</CopyH1>
      <CopyParagraph>
        Reimagining books, movies and television series for mainstream gaming. StoryPlay immerses
        the player as a character into the story so they can experience and interact with it
        directly
      </CopyParagraph>
    </CopyArea>
  )
}

const CopyArea = styled.div`
  text-align: center;
  margin: 2rem 25%;
  padding: 5rem;
`

const CopyH1 = styled.h1`
  font-size: 2.4rem;
  text-transform: uppercase;
  letter-spacing: 1rem;
  font-weight: 900;
  margin-bottom: 2rem;
`

const CopyParagraph = styled.p`
  font-size: 1.8rem;
  line-height: 2.8rem;
  letter-spacing: 1.5px;
  font-weight: 800;
`

export default CopySection
