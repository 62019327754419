import * as React from 'react'
import styled from '@emotion/styled'
import Link from 'gatsby-link'

const OPEN = 'OPEN'
const HIDDEN = 'HIDDEN'
const DISABLE = 'DISABLE'

const storageKey = 'ALLOWCOOKIES'

class CookieBanner extends React.Component {
  state = {}

  componentDidMount() {
    if (window.localStorage && window.localStorage.getItem(storageKey)) {
      this.setState({ bannerStatus: DISABLE })
    } else {
      this.setState({ bannerStatus: OPEN })
    }
  }

  enableGA() {
    window[`ga-disable-${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`] = false
    if (process.env.NODE_ENV === 'production' && typeof ga === 'function') {
      window.ga('send', 'pageview')
    }
  }

  handleClose = e => {
    window.localStorage.setItem(storageKey, true)
    this.enableGA()
    this.setState({ bannerStatus: HIDDEN })
  }

  render() {
    return (
      <Root open={this.state.bannerStatus === OPEN}>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window['ga-disable-${process.env.GATSBY_GOOGLE_ANALYTICS_ID}'] = true
            `
          }}
        />
        <Description>
          <span>{this.props.children}</span>
          <CookieText>
            We use cookies to improve your experience on our websites. By continuing to browse you
            are giving consent to cookies being used.
          </CookieText>
          <span>
            <PrivacyLink to={this.props.to} href={this.props.href} />
            <SmallButton label="OK" onClick={this.handleClose} style={{ marginLeft: '1.6rem' }}>
              OK
            </SmallButton>
          </span>
        </Description>
      </Root>
    )
  }
}

function PrivacyLink({ to, href }) {
  if (to) {
    return <InternalPrivacyLink to={to}>Learn More</InternalPrivacyLink>
  }
  if (href) {
    return <ExternalPrivacyLink href={href}>Learn More</ExternalPrivacyLink>
  }
  console.warn('Privacy link missing href or to prop.')
  return null
}

const Root = styled.div`
  /* shape and position */
  bottom: ${props => (props.open ? 0 : '-23rem')};
  left: 0;
  padding: 0 1rem;
  position: fixed;
  width: 100%;
  z-index: 99999;
  /* animations */
  transition: all 500ms ease-out;
  /* effects */
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  @media (min-width: 750px) {
    padding: 2rem 3rem;
  }
`

const Description = styled.p`
  font-size: 1.6rem;
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  line-height: 1.4;

  @media (min-width: 750px) {
    flex-direction: row;
    padding: 0 3rem;
  }
`
const SmallButton = styled.button`
  font-size: 1.6rem;
  margin-top: 1rem;
  background-color: #2d172c;
  color: white;
  border-radius: 8px;
  padding: 1rem 3rem;
  font-family: Roboto;
  cursor: pointer;
  transition: transform 200ms ease-out;
  &:hover {
    transform: scale(1.1);
  }
`

const InternalPrivacyLink = styled(Link)`
  margin-left: 1.6rem;
`
const ExternalPrivacyLink = InternalPrivacyLink.withComponent('a')

const CookieText = styled.span`
  margin: 1rem;
  @media (min-width: 1276px) {
    margin: 0;
  }
`

export default CookieBanner
