import React from 'react'
import styled from '@emotion/styled'

export const H3 = styled.h3`
  font-family: 'East Sea Dokdo', cursive;
  font-size: 4.8rem;
  line-height: 1;
  text-transform: uppercase;
`

export default H3