import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'


const InputRoot = styled.input`
  box-sizing: border-box;
  background-color: white;
  border: 0;
  vertical-align: middle;
  padding: 20px;  
  line-height: 1;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;

  &::placeholder {
    opacity: 0.75;    
  }

  @media (min-width: 768px) {
    width: ${props => props.full ? '100%' : 'auto'};
    margin-top: ${props => props.full ? '5px' : 0};
    margin-bottom: ${props => props.full ? '5px' : 0};
  }

  &::placeholder {
    font-style: italic;
  }  
`

class Input extends React.Component {
  updateForm = (e) => {
    // console.log('input: onChange', e)
    // console.log('context', this.context)
    this.context.updateFormState(e)
  }

  render() {
    const value = this.context.formState[this.props.name]
    return (
      <InputRoot {...this.props} value={value || ''} onChange={this.updateForm} />
    )
  }
}

Input.contextTypes = {
  updateFormState: PropTypes.func,
  formState: PropTypes.object,
}

export default Input