import React from 'react'
import styled from '@emotion/styled'
import ReactPlayer from 'react-player'

const VideoPlayer = styled(ReactPlayer)`
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.5);
  border-radius: 28px;
  overflow: hidden;
  ${props =>
    props.responsive &&
    `
      position: absolute;
      top: 0;
      left: 0;
    `}
`

export default VideoPlayer
