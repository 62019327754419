import React from 'react'
import styled from '@emotion/styled'

import Facebook from '../SocialMenu/facebook-logo-button.png'
import Instagram from '../SocialMenu/instagram.png'
import Twitter from '../SocialMenu/twitter-logo-button.png'
import Youtube from '../SocialMenu/youtube.png'
import MadeBy from '../SocialMenu/madeBy.png'
import ESRB from '../ESRB'
import TikTok from '../SocialMenu/tiktok.png'

function FooterSection({ footerMenu, socialMenu }) {
  return (
    <StyledFooter>
      <DetailsSection>
        <ContactPrivacy>
          <a href="https://cosmicforces.com/privacy-policy">Privacy Policy</a>
          <a href="https://cosmicforces.com/california-privacy-policy/">
            California Privacy Policy
          </a>
          <a href="https://cosmicforces.com/terms-of-use/">Terms of Use</a>
        </ContactPrivacy>
        <ul>
          <li>
            <a href="https://www.instagram.com/Goosebumps_DON/">
              <img src={Instagram} alt="Instagram" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/Goosebumps_DON">
              <img src={Twitter} alt="Twitter" />
            </a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@goosebumps_don">
              <img src={TikTok} alt="TikTok" />
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCTMpUwWrUfKA5b9pqOGt39A">
              <img src={Youtube} alt="Youtube" />
            </a>
          </li>
          <li>
            <a href="https://www.cosmicforces.com">
              <img src={MadeBy} alt="Made By Cosmic Forces" />
            </a>
          </li>
        </ul>
      </DetailsSection>
      <CopyContainer>
        <ESRB rating="E10" width={70} height={91} />
        <CopyContent>
          <p>
            GOOSEBUMPS and associated logos are trademarks of Scholastic Inc. Goosebumps, the Movie
            ©2015 Columbia Pictures Industries, Inc. Goosebumps 2, the Movie ©2018 Columbia Pictures
            Industries, Inc. Goosebumps Night of Scares game software (excluding Columbia Pictures
            and Scholastic elements) ©2015 Cosmic Forces, Inc. Goosebumps Dead of Night game
            software (excluding Columbia Pictures and Scholastic elements) ©2020 Cosmic Forces, Inc.
            All Rights Reserved.
          </p>
          <p>
            Nintendo Switch is a trademark of Nintendo. The “PS” Family logo and “PS4” are
            registered trademarks of Sony Interactive Entertainment Inc. Xbox One and the Xbox logos
            are trademarks of the Microsoft group of companies and are used under license from
            Microsoft. Steam and the Steam logo are trademarks and/or registered trademarks of Valve
            Corporation in the U.S. and/or other countries.
          </p>
        </CopyContent>
      </CopyContainer>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  background-image: linear-gradient(to right, #1e1f1d -1%, #000000);
  padding: 3rem 6rem;
  /* display: flex; */
  justify-content: center;
  box-shadow: inset 2px 4px 12px rgba(0, 0, 0, 0.5);
  align-items: center;
  flex-wrap: wrap;
  padding: 3rem;

  @media (min-width: 1183px) {
    justify-content: space-between;
    padding: 3rem 6rem;
    flex-wrap: nowrap;
  }

  & ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    & li {
      display: inline-block;
      margin-left: 20px;

      &:last-child {
        @media (min-width: 620px) {
          border-left: 1px solid #979797;
          margin-left: 30px;
          padding-left: 30px;
        }
      }
    }
  }
`

const DetailsSection = styled.div`
  color: white;
  text-align: center;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }

  a {
    color: white;
  }
  @media (min-width: 1183px) {
    text-align: left;
  }
`

const CopyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media (min-width: 760px) {
    justify-content: space-between;
  }
`
const CopyContent = styled.div`
  width: 90%;
  p {
    font-size: 1.2rem;
    opacity: 0.6;
    margin-right: 1rem;
    text-align: left;
  }
`

const ContactPrivacy = styled.p`
  font-size: 1.2rem;
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
  a {
    text-decoration: none;
  }
  a:not(:last-child) {
    margin-right: 5rem;
  }
`

export default FooterSection
