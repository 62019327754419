import React from 'react'
import styled from '@emotion/styled'
import CosmicForces from './CosmicForces.png'
import ColumbiaPictures from './ColumbiaPictures.png'
import Scholastic from './Scholastic.png'
import TextureBackground03 from './TextureBackground03.jpg'

function Partners() {
  return (
    <Root>
      <a href="https://cosmicforces.com">
        <img src={CosmicForces} alt="Cosmic Forces" width="360" height="106" />
      </a>
      <a>
        <img src={Scholastic} alt="Scholastic" width="419" height="52" />
      </a>
      <a>
        <img src={ColumbiaPictures} alt="Sony Pictures" width="400" height="107" />
      </a>
    </Root>
  )
}

const Root = styled.div`
  background: url(${TextureBackground03}) no-repeat;
  background-size: cover;
  padding: 4rem;
  box-shadow: inset 2px 4px 12px rgba(0, 0, 0, 0.5);

  & a {
    display: inline-block;
    padding: 2rem 1rem;
  }

  & img {
    max-width: 100%;
    height: auto;
    display: inline-block;
  }

  @media (min-width: 480px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10rem 5rem;

  }

  @media (min-width: 768px) {
    & a {
      padding: 2rem 4rem;
    }
  }

`

export default Partners
