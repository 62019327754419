import React from 'react'
import styled from '@emotion/styled'
import HeroDesktopImg from './Hero.jpg'

const HeroSection = styled.div`
  height: 95vh;
  background-image: url(${HeroDesktopImg});
  background-size: cover;
  background-position: 40% bottom;
`

export default HeroSection
