import React from 'react'
import styled from '@emotion/styled'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
  DotGroup
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import Back from './Back.png'
import Next from './Next.png'

function Carousel({ dots, children, active, totalSlides, ...props }) {
  return (
    <CarouselProvider
      {...props}
      naturalSlideWidth={1440}
      naturalSlideHeight={810}
      totalSlides={totalSlides}
      style={{ position: 'relative' }}
    >
      <Slider>{children}</Slider>
      {active && dots}
      <div style={{ display: 'flex', justifyContent: 'center' }}></div>
      {active && (
        <>
          <Buttons style={{ left: 0 }}>
            <_ButtonBack>
              <img src={Back} alt="back" />
            </_ButtonBack>
          </Buttons>
          <Buttons style={{ right: 0 }}>
            <_ButtonNext>
              <img src={Next} alt="back" />
            </_ButtonNext>
          </Buttons>
        </>
      )}
    </CarouselProvider>
  )
}

const ButtonsContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`

const Buttons = styled.div``

const _ButtonBack = styled(ButtonBack)`
  background: none;
  border: none;
  left: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.85;
  &:focus {
    opacity: 1;
    outline: 0;
  }
  &:disabled {
    opacity: 0.25;
  }
`
const _ButtonNext = styled(ButtonNext)`
  background: none;
  border: none;
  right: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.85;
  &:focus {
    outline: 0;
    opacity: 1;
  }
  &:disabled {
    opacity: 0.25;
  }
`

export { Slide, Carousel, Dot }
