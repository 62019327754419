import React from "react"
import styled from "@emotion/styled"
import LogoImg from "./fj09adf.png"
import LogoMobile from "./asdf8asdf.png"

function Logo(props) {
  return (
    <>
      <Img src={LogoImg} {...props} className="desktop" />
      <Img src={LogoMobile} {...props} className="mobile" />
    </>
  )
}

const Img = styled.img`
  max-width: 100%;
  width: 80vh;
  height: auto;

  &.desktop {
    display: none;
  }

  @media (min-width: 768px) {
    &.desktop {
      display: initial;
    }
    &.mobile {
      display: none;
    }
  }
`

export default Logo
