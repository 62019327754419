import React, { useState } from 'react'
import styled from '@emotion/styled'
import RootBG from './RootBackground'
import Button from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import Form, { addNetlify } from '../components/Form'

function SupportContact ({ title, formName }) {
  const [open, setOpen] = useState(false)

  return (
    <Container>
      <Header>
        <h1 onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
          Press Inquiries <Caret icon={open ? faCaretDown : faCaretRight} />
        </h1>
      </Header>
      {open && (
        <>
          <FormContainer>
            <NetlifyForm name='Media-Inquiries'>
              <Form.Group>
                <Input full name='name' placeholder='Name' />
                <Input full name='email' placeholder='Email' />
              </Form.Group>
              <TextArea name='message' placeholder='Message' full rows={6} />
              <FormButton type='submit'>Send Message</FormButton>
            </NetlifyForm>
          </FormContainer>
        </>
      )}
      <Header style={{ display: 'initial' }}>
        <h1 style={{ margin: '4rem 0 2rem 0' }}>Download Press Kit</h1>
        <DownloadButton href="Goosebumps_DON_PressKit_v1.0.zip">Download</DownloadButton>
      </Header>
    </Container>
  )
}

const Container = styled(RootBG)`
  text-align: center;
  background: black;

  color: #afafaf;
  a {
    color: #41c8c7;
    text-decoration: none;
  }
`

const DownloadButton = Button.withComponent('a')

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-weight: 600;
    font-size: 4rem;
    font-family: "East Sea Dokdo";
    margin: 0;
    white-space: nowrap;
    @media (min-width: 480px) {
      font-size: 6.5rem;
    }
  }
`

const Caret = styled(FontAwesomeIcon)`
  vertical-align: middle;
  margin-left: 2rem;
  font-size: 0.75em;
`

const FormContainer = styled.div`
  list-style-type: none;
  font-size: 4.6rem;
  text-transform: uppercase;
  margin: 3rem auto;
  max-width: 800px;
`

const Input = styled(Form.Input)`
  border: 2px solid black;
  font-size: 2rem;
  font-family: "Special Elite";
`

const TextArea = styled(Form.TextArea)`
  border: 2px solid black;
  font-size: 2rem;
  font-family: "Special Elite";
`

const FormButton = styled(Button)`
  font-size: 2rem;
  cursor: pointer;
`

const NetlifyForm = addNetlify(Form)

export default SupportContact
