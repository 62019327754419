import React, { useState, useRef, useEffect } from 'react'
import { animated, useTransition, useSpring, useChain, config } from 'react-spring'
import styled from '@emotion/styled'
import Button from '../Button'
import Media from 'react-media'
import PlayStation from './PlayStation.png'
import Steam from './Steam.png'
import Switch from './Switch.png'
import Xbox from './XBOX.png'
import { OutboundLink, trackCustomEvent } from 'gatsby-plugin-google-analytics'

const data = [
  {
    key: '1',
    src: PlayStation,
    url: 'https://store.playstation.com/en-us/product/UP5950-CUSA18430_00-GBDONENPS4DIG020'
  },
  {
    key: '2',
    src: Xbox,
    url: 'https://www.microsoft.com/store/productid/9nxpdn153q3l'
  },
  {
    key: '3',
    src: Steam,
    url: 'https://store.steampowered.com/app/1173150/Goosebumps_Dead_of_Night/?beta=0'
  },
  {
    key: '4',
    src: Switch,
    url: 'https://www.nintendo.com/games/detail/goosebumps-dead-of-night-switch/'
  }
]

function PlatformButton(props) {
  const [open, setToggle] = useState(false)
  const [loading, setLoading] = useState(true)

  const springRef = useRef()
  const { size, opacity, ...rest } = useSpring({
    ref: springRef,
    config: config.stiff,
    from: { opacity: 1, transform: 'scale(1)' },
    to: { opacity: open ? 0 : 1, transform: open ? 'scale(0)' : 'scale(1)' }
  })
  const containerProps = useSpring({
    config: config.molasses,
    borderColor: 'rgba(0,174,164,0.6)',
    backgroundColor: 'rgba(0,0,0,.8)',
    from: { borderColor: 'rgba(0,174,164,0)', backgroundColor: 'rgba(0,0,0,0)' }
  })

  const transRef = useRef()
  const transitions = useTransition(open ? data : [], item => item.key, {
    ref: transRef,
    unique: true,
    trail: 400 / data.length,
    from: { opacity: 0, transform: 'scale(0)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0)' }
  })

  useChain(open ? [transRef, springRef] : [springRef, transRef], [0, open ? 0.1 : 0.6])

  useEffect(() => {
    setLoading(false)
  }, [])

  function toggle(e) {
    setToggle(!open)
    props.handleClick()
  }

  function openDownloads(e) {
    e.preventDefault()
  }

  // Prevents rendering on build since media queries are browser dependent
  if (loading) return null

  return (
    <Media
      queries={{
        desktop: "(min-width: 900px)"
      }}
    >
      {matches =>
        matches.desktop ? (
          <>
            <Container>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  margingTop: -25,
                  height: 60
                  // position: 'absolute'
                  // position: open ? 'absolute' : 'relative'
                }}
              >
                <AButton
                  style={{
                    ...rest,
                    transition: "none",
                    width: "100%",
                    maxWidth: "200px",
                    height: 50
                  }}
                  onClick={(e) => {
                    toggle(e)
                    trackCustomEvent({
                      category: "Button",
                      action: "Clicked",
                      label: "Get it Now"
                    })
                  }}
                >
                  Get it Now
                </AButton>
              </div>
              {open && (
                <ButtonContainer style={containerProps}>
                  {transitions.map(({ item, key, props }) => (
                    <TracedLink href={item.url} target="_blank" key={key}>
                      <Img src={item.src} style={{ ...props }} />
                    </TracedLink>
                  ))}
                </ButtonContainer>
              )}
            </Container>
          </>
        ) : (
            <>
              <MobileContainer>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    margingTop: -25,
                    height: 60
                  }}
                >
                  <AButton
                    style={{
                      ...rest,
                      transition: "none",
                      width: "100%",
                      maxWidth: "200px",
                      height: 50
                    }}
                    onClick={(e) => {
                      toggle(e)
                      trackCustomEvent({
                        category: "Button",
                        action: "Clicked",
                        label: "Get it Now"
                      })
                    }}
                  >
                    Get it Now
                </AButton>
                </div>
                {open && (
                  <MobileButtonContainer style={containerProps}>
                    {transitions.map(({ item, key, props }) => (
                      <TracedLink href={item.url} target="_blank" key={key}>
                        <Img src={item.src} style={{ ...props }} />
                      </TracedLink>
                    ))}
                  </MobileButtonContainer>
                )}
              </MobileContainer>
            </>
          )
      }
    </Media>
  )
}

const MobileContainer = styled(animated.div)`

`
const MobileButtonContainer = styled(animated.div)`
  a {
    display: block;
  }
  width: 200px;
  margin: 0 auto;
  position: absolute;
  margin-top: -80px;
  border: 2px solid #00aea4;
  background-color: rgba(0, 0, 0, 0.8);
`

const AButton = animated(Button)

const Img = styled(animated.img)`
  max-width: 100px;
  height: auto;
  margin: 10px;
`

const TracedLink = styled(OutboundLink)`
  opacity: ${props => !props.href ? 0.5 : 1};
  cursor: ${props => !props.href ? 'initial' : 'pointer'};

  &:nth-child(2) {
    grid-column-start: auto;
  }
  @media (min-width: 768px) {
    &:nth-child(2) {
      grid-column-start: 2;
    }
  }
`

const Container = styled(animated.div)``
const ButtonContainer = styled(animated.div)`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  border: 2px solid #00aea4;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  margin: 0 auto;
  padding: 2rem;
  position: absolute;
  margin-left: 158px;
  margin-top: -100px;
`

export default PlatformButton
