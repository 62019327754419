import styled from '@emotion/styled'

const RootBG = styled.div`
  padding: 3rem;
  box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(120deg, #242424 -21%, #121212 41%, #222222);
  box-shadow: inset 2px 4px 12px rgba(0, 0, 0, 0.5);
  @media (min-width: 376px) {
    padding: 7rem;
  }
`

export default RootBG
