import React from 'react'

function RatedT(props) {
  return (
    <svg width={41} height={61} viewBox="0 0 41 61" {...props}>
      <title>{'Rated-T'}</title>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFFFFE" d="M.562 60.234h39.684V.706H.562z" />
        <path fill="#1A1919" d="M1.138 59.597H39.67V1.345H1.138z" />
        <path
          fill="#FFFFFE"
          d="M3.077 13.074h34.376V3.415H3.077zM3.062 50.691h34.395v-35.51H3.062zM3.068 57.496v-4.715h6.906v1.103H5.842v.674h3.497v1.1H5.842v.738h4.292v1.1H3.068M16.001 54.154c-.043-.158-.198-.262-.4-.319a2.521 2.521 0 00-.717-.087c-.61 0-.883.099-.883.275 0 .697 4.87.26 4.87 1.975 0 1.091-1.638 1.625-3.963 1.625-2.235 0-3.692-.727-3.751-1.579h2.656c.06.17.225.291.461.371.223.079.514.12.789.12.693 0 1.155-.123 1.155-.346 0-.715-4.873-.225-4.873-2.022 0-1.002 1.553-1.503 3.671-1.503 2.349 0 3.421.666 3.623 1.49h-2.638M23.024 55.853v1.643H20.25v-4.715h3.999c2.818 0 3.749.431 3.749 1.347 0 .545-.434.998-1.422 1.157.893.179 1.435.291 1.435 1.242 0 .613-.036.826.293.826v.143h-2.813c-.069-.106-.138-.407-.138-.825 0-.619-.189-.818-1.482-.818h-.847zm0-1.027h1.091c.859 0 1.32-.125 1.32-.45 0-.332-.47-.492-1.118-.492h-1.293v.942zM29.703 57.496v-4.715h4.047c1.906 0 3.423.256 3.423 1.189 0 .486-.567.84-1.106 1.024.916.132 1.408.574 1.408 1.08 0 1.05-1.443 1.422-3.646 1.422h-4.126zm2.773-1.969v.869h1.211c.687 0 1.203-.08 1.203-.432 0-.412-.645-.437-1.532-.437h-.882zm0-1.643v.789h1.156c.553 0 .954-.142.954-.412 0-.369-.547-.377-1.13-.377h-.98z"
        />
        <path
          fill="#1A1919"
          d="M5.932 23.798l26.609-6.636 2.047 7.091-8.55 2.131 5.733 19.926-9.513 2.373-5.737-19.926-8.546 2.133-2.043-7.092M29.381 5.507h2.26l2.621 2.985-.091-1.248V5.507h2.31v5.465h-2.256l-2.626-2.976.044 1.29.048 1.686h-2.31V5.507M21.241 5.507h5.992v1.27h-3.585v.787h3.034v1.275h-3.034v.856h3.728v1.277h-6.135V5.507M12.818 5.507h5.994v1.27h-3.585v.787h3.031v1.275h-3.031v.856h3.73v1.277h-6.139V5.507M4.032 5.507h6.739v1.434H8.609v4.031H6.195V6.941H4.032V5.507"
        />
      </g>
    </svg>
  )
}

export default RatedT
