import React from 'react'
import styled from '@emotion/styled'

import RatedE from './RatedE'
import RatedE10 from './RatedE10'
import RatedT from './RatedT'
import RatedM from './RatedM'
import RatedA from './RatedA'
import RatedRP from './RatedRP'

function ESRB({ rating, width, height }) {
  switch (rating) {
    case 'E':
      return <RatedE width={width} height={height} />
    case 'E10':
      return <RatedE10 width={width} height={height} />
    case 'T':
      return <RatedT width={width} height={height} />
    case 'M':
      return <RatedM width={width} height={height} />
    case 'A':
      return <RatedA width={width} height={height} />
    case 'RP':
      return <RatedRP width={width} height={height} />
    default:
      console.warn('Invalid rating prop supplied to ESRB component')
      return <path />
  }
}

export default ESRB
