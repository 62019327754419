import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-scroll'

export const scrollOptions = {
  duration: 800,
  delay: 100,
  smooth: true,
  offset: -50,
}

function ScrollLink(props) {
  const mergedProps = { ...scrollOptions, ...props}
  return <Link {...mergedProps} />
}

export default ScrollLink