import React, { useLayoutEffect, useRef } from 'react'
import VideoCover from 'react-video-cover'
import styled from '@emotion/styled'

function VideoBackground ({src, overlay, ...props}) {
  const ref = useRef(null)

  useLayoutEffect(() => {
    if (ref.current) {
      try {
        ref.current.loop = true
        ref.current.muted = true
        ref.current.play()
      } catch (error) {
        console.log('Error playing video: ', error);
      }
    }
  }, [ref.current])

  if (typeof src === 'undefined' || !src) return null

  const videoOptions = {
    src,
    loop: true,
    ref
  }
  return (
    <Container {...props}>
      <VideoCover videoOptions={videoOptions} />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: -2;
  background-image: url(${props => props.fallback});
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
`

export default VideoBackground
