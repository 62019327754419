import React, { useState } from 'react'
import Header from './header'
import HeroSection from './Hero'
import CopySection from './Copy'
import VideoBackground from './VideoBackground'
import video from './VideoBackground/CosmicForces_HeaderVideo_v03.mp4'
import styled from '@emotion/styled'
import GoosebumpsDON from './Goosebumps'
import Hero from './Hero'
import Logo from './Logo'
import Button from './Button'
import VideoPlayer from './VideoPlayer'
import ScrollLink from './ScrollLink'
import { Element } from 'react-scroll'
import { H3 } from './Headings'
import Platforms from './Platforms'
import PlatformButton from './PlatformButton'
import trailerPoster from './TrailerPoster.jpg'
import YoutubeCarousel from './YoutubeCarousel'
import NightOfScares from './NightOfScares'
import Footer from './Footer'
import Media from 'react-media'
import CookieBanner from './CookieBanner'
import SupportContact from './SupportContact'
import MediaContact from './MediaContact'
import Helmet from 'react-helmet'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'


import Partners from './Partners'

import scratchedTexture from './scratched_texture.jpg'
import textureBackground02 from './TextureBackground02.jpg'
import featureShot_1 from './thumbnails/SlappysRoom.jpg'
import featureShot_2 from './thumbnails/feature-shot-2.jpg'
import featureShot_3 from './thumbnails/feature-shot-3.jpg'
import siteThumbnail from './thumbnails/logo-square-2.jpg'

import '../styles/layout.css'
import styles from './layout.module.css'

function Layout({ children, onHideNav, onShowNav, showNav, siteTitle, navMenu, footerMenu }) {
  const [playing, setPlaying] = useState(true)
  const [open, setOpen] = useState(false)
  const [lightMode, setLightMode] = useState(trailerPoster)

  function handlePlayVideo(e) {
    setLightMode(false)
    // React Player ignores playing prop while in light mode.
    setTimeout(function () {
      setPlaying(true)
    }, 1000)
  }

  function handleOnPlay() {
    if (!playing) {
      setPlaying(true)
    }
  }

  function handleOnPause() {
    if (playing) {
      setPlaying(false)
    }
  }

  function handlePlatformClick() {
    setOpen(true)
  }

  return (
    <>
      <Helmet>
        <title>Goosebumps Dead of Night</title>
        <meta
          name="keywords"
          content="goosebumps, goosebumpsgame, rlstine, slappy, slappythedummy, scholastic, goose bumps, night of scares, rl stine"
        />
        <meta property="og:url" content="https://www.goosebumpsgame.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Goosebumps Dead of Night" />
        <meta
          property="og:description"
          content="Slappy has escaped from the pages of Goosebumps and reunited his family of Monsters. It’s up to you to stop Slappy’s dastardly plan to unleash the Goosebumps monsters into our world."
        />
        <meta property="og:image" content={`https://www.goosebumpsgame.com${siteThumbnail}`} />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Goosebumps Dead of Night",
            "thumbnailUrl": "https://www.goosebumpsgame.com${siteThumbnail}",
            "logo": "https://www.goosebumpsgame.com${siteThumbnail}",
            "image":\"https://www.goosebumpsgame.com${siteThumbnail}",
            "description": "Slappy has escaped from the pages of Goosebumps and reunited his family of Monsters. It’s up to you to stop Slappy’s dastardly plan to unleash the Goosebumps monsters into our world.",
            "brand": {
              "@type": "Brand",
              "name": "Cosmic Forces"
            },
            "review": {
              "@type": "Review",
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": "7",
                "bestRating": "7",
                "reviewCount": "1"
              },
              "author": {
                "@type": "Organization",
                "name": "Cosmic Forces"
              }
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "7",
              "bestRating": "7.0",
              "reviewCount": "1"
            }
          }
        `}
        </script>
      </Helmet>
      <CookieBanner href="https://www.cosmicforces.com/privacy-policy">
        <img
          src={siteThumbnail}
          alt="Goosebumps Dead of Night"
          width="50"
          height="50"
          style={{
            borderRadius: 8,
            textAlign: "left",
            alignSelf: ""
          }}
        />
      </CookieBanner>
      <StyledHero>
        <HeroContainer>
          <p>
            <Logo />
          </p>
          <ButtonsContainer>
            <PlatformButton handleClick={handlePlatformClick} />
            {!open && (
              <TrailerButton
                as={ScrollLink}
                activeClass="active"
                to="trailer"
                onClick={(e) => {
                  trackCustomEvent({
                    category: "Button",
                    action: "Clicked",
                    label: "Watch Trailer"
                  })
                  handlePlayVideo(e)
                }}
              >
                Watch Trailer
              </TrailerButton>
            )}
          </ButtonsContainer>
        </HeroContainer>
      </StyledHero>

      <Section
        background={scratchedTexture}
        style={{
          padding: "10rem 2rem",
          boxShadow: "inset 0 9px 18px rgba(0,0,0,0.5)",
          backgroundSize: "cover"
        }}
      >
        <div
          style={{
            maxWidth: 1240,
            margin: "0 auto"
          }}
        >
          <H3 style={{ textAlign: "center" }}>
            Available Now on Switch, PlayStation, Xbox One, and Steam.
          </H3>
          <VideoSection name="trailer" className="element">
            <VideoWrapper>
              <VideoPlayer
                url="https://vimeo.com/424043074"
                width="100%"
                height="100%"
                responsive="true"
                light={lightMode}
                playing={playing}
                volume={0.5}
                onClick={() => setLightMode(false)}
                controls={true}
              />
            </VideoWrapper>
          </VideoSection>
        </div>
      </Section>
      <GameFeatures>
        <Container>
          <Content>
            <H3>Suspenseful & terror-packed gameplay</H3>
            <p>
              Slappy has escaped from the pages of Goosebumps and reunited his family of Monsters.
              It’s up to you to stop Slappy’s dastardly plan to unleash the Goosebumps monsters into
              our world. To trap Slappy back into his book you’ll have to solve puzzles and outwit
              the Goosebumps monsters. Can you survive Slappy’s wrath and defeat him?
            </p>
          </Content>
          <Thumbnail direction="right">
            <ThumbnailImg src={featureShot_1} width="495" height="393" />
          </Thumbnail>
        </Container>
        <Container>
          <Media
            queries={{
              desktop: "(min-width: 900px)"
            }}
          >
            {matches =>
              matches.desktop ? (
                <>
                  <Thumbnail direction="left">
                    <ThumbnailImg src={featureShot_2} width="495" height="393" />
                  </Thumbnail>
                  <Content>
                    <H3>Escape from monsters based on the bestselling Goosebumps series</H3>
                    <p>
                      Hide from monsters summoned from classic Goosebumps tales while exploring and
                      solving puzzles.
                    </p>
                  </Content>
                </>
              ) : (
                  <>
                    <Content>
                      <H3>Escape from monsters based on the bestselling Goosebumps series</H3>
                      <p>
                        Hide from monsters summoned from classic Goosebumps tales while exploring and
                        solving puzzles.
                    </p>
                    </Content>
                    <Thumbnail direction="left">
                      <ThumbnailImg src={featureShot_2} width="495" height="393" />
                    </Thumbnail>
                  </>
                )
            }
          </Media>
        </Container>
        <Container>
          <Content>
            <H3>Solve puzzles in classic Goosebumps locations</H3>
            <p>
              As you find the missing pages from R.L. Stine’s book, you’ll need to navigate the
              twisted and overgrown conservatory to confront Dr. Brewer and climb the heights of
              Tesla’s Tower to stop Slappy’s plans.
            </p>
          </Content>
          <Thumbnail direction="right">
            <ThumbnailImg src={featureShot_3} width="495" height="393" />
          </Thumbnail>
        </Container>
      </GameFeatures>
      <Platforms />
      <YoutubeCarousel />
      <Section
        background={textureBackground02}
        style={{
          padding: "5rem 2rem",
          boxShadow: "inset 0 9px 18px rgba(0,0,0,0.5)",
          backgroundSize: "cover"
        }}
      >
        <NightOfScares />
      </Section>
      <Partners />
      <SupportContact />
      <MediaContact />
      <Footer />
    </>
  )
}

const VideoSection = styled(Element)`
  @media (min-width: 768px) {
    margin: 0 5rem;
  }
`

const ButtonsContainer = styled.div`
  margin: -9rem auto 0;
  align-items: center;
  width: 200px;

  @media (min-width: 768px){
    margin-top: 0;
  }
  @media (min-width: 900px){
    margin: 0 auto;
    width: 600px;
  }
`

const StyledHero = styled(Hero)`
  padding-bottom: 8rem;
  @media (min-width: 900px){
    padding-bottom: 0;
  }
`

const HeroContainer = styled.div`
  padding-top: 1rem;
  text-align: center;
  @media (min-width: 900px){
    padding-top: 7rem;
  }
`

const TrailerButton = styled(Button)`
  border: 2px solid rgba(255,255,255, .75);
  color: rgba(255,255,255, .75);
  display: inline-block;
  width: 100%;
  max-width: 200px;
  box-sizing: border-box;
  padding: 10px 22px;
  height: 50px;
`

const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`

const PlayButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  text-align: center;
  cursor: pointer;
`

const Content = styled.div`
  grid-column-end: span 7;
`

const Thumbnail = styled.div`
  grid-column-start: 1;

  @media (min-width: 768px) {
    grid-column-start: auto;
    grid-column-end: span 5;

    & img:hover {
      transform: ${props => (props.direction === "left" ? "rotate(-3deg)" : "rotate(4deg)")};
    }
  }
`

const ThumbnailImg = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 24px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.5);
  transition: all 700ms cubic-bezier(1, -0.53, 0.405, 1.425);
`

const Section = styled.section`
  background-image: url(${props => props.background});
`

const GameFeatures = styled.div`
  padding: 10rem 0;
  background-color: #000;
`

const Container = styled.div`
  margin: 0 auto 7rem auto;
  padding: 0 2rem;

  max-width: 1240px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 20px;
  }
`

export default Layout
