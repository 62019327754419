import React from 'react'
import styled from '@emotion/styled'

import playstation from './playstation.png'
import steam from './steam.png'
import nintendo from './switch.png'
import xbox from './xbox.png'
import RootBG from '../RootBackground'

function Platforms () {
  return (
    <RootBG>
      <Caption style={{textAlign: 'center'}}>
       Available Now on Switch, PlayStation, Xbox One, and Steam.
      </Caption>
      <Container>
        <Platform>
          <img src={nintendo} width='111' height='116' alt='Nintendo Switch' />
        </Platform>
        <Platform>
          <img src={playstation} width='134' height='101' alt='PlayStation' />
        </Platform>
        <Platform>
          <img src={xbox} width='97' height='101' alt='Xbox' />
        </Platform>
        <Platform>
          <img src={steam} width='120' height='97' alt='Steam' />
        </Platform>
      </Container>
    </RootBG>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 2rem auto;

  max-width: 1240px;
`

const Platform = styled.div`
  grid-column-end: span 6;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    max-width: 100%;
    height: auto;
  }

  @media (min-width: 768px) {
    grid-column-end: span 3;
  }
`

const Caption = styled.p`
  font-family: 'East Sea Dokdo', sans-serif;
  font-weight: 700;
  font-size: 4rem;
  margin: 2rem 0 5rem 0;
  line-height: 1.25;
  text-transform: uppercase;
  @media (min-width: 404px) {
    font-size: 4.8rem;
  }
`

export default Platforms
