import React from 'react'
import styled from '@emotion/styled'

import styles from './header.module.css'

function Header ({onHideNav, onShowNav, showNav, siteTitle, navMenu}) {
  return (
    <div className={styles.root}>
      <NavContainer >
        <Bar1 />
        <Bar2 />
        <Bar3 />
      </NavContainer>
    </div>
  )
}

const NavContainer = styled.div`
  display: inline-block;
  position: absolute;
  top: 3rem;
  left: 3rem;
  cursor: pointer;
  padding: 35px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 2px 4px 8px rgba(0,0,0,.5);
`

const Bar = styled.div`
  width: 25px;
  height: 3px;
  background-color: #333;
  margin-bottom: 6px;
  transition: 0.4s;
`
const Bar1 = styled(Bar)`
`
const Bar2 = styled(Bar)`
`

const Bar3 = styled(Bar)`
  margin-bottom: 0;
`
export default Header
