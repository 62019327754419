import React from 'react'
import styled from '@emotion/styled'
import { always, map, mapObjIndexed, isNil, or, pipe, join, keys, values } from 'ramda'
import { H1, BoldParagraph } from '../global-styles'
// import { isNotNil } from '../../utils'
import Input from './Input'
import Form from './Form'
import TextArea from './TextArea'
import PropTypes from 'prop-types'

const encode = pipe(
  mapObjIndexed((val, key) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`),
  values,
  join('&'),
)

const clearKeys = mapObjIndexed(always(''))

export const addFormState = Component => {
  class StatefulForm extends React.Component {
    state = {}

    getChildContext() {
      return {
        updateFormState: this.updateFormState,
        formState: this.state,
      }
    }

    updateFormState = e => this.setState({
      [e.target.name]: e.target.value,
    })

    render() {
      return (
        <Component {...this.props} />
      )
    }
  }

  StatefulForm.childContextTypes = {
    updateFormState: PropTypes.func,
    formState: PropTypes.object,
  }

  return StatefulForm
}

export const addNetlify = FormComponent => {
  class NetlifyForm extends React.Component {

    state = { submitted: false }

    getChildContext() {
      return {
        updateFormState: this.updateFormState,
        formState: this.state,
      }
    }

    updateFormState = e => this.setState({
      [e.target.name]: e.target.value,
    })

    requestAlertClose = () => this.setState({
      success: undefined,
      error: undefined,
    })

    handleSubmit = e => {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': this.props.name, ...this.state })
      })
        .then((success) => {
          this.setState(
            prevState => ({ submitted: true, success: { message: 'Your information was submitted successfully!' } }),
          )
        })
        .catch(error => {
          this.setState({ submitted: true, success: { message: error.message } })
        })
      e.preventDefault()

    }

    render() {
      const { children, ...props } = this.props
      return (
        <div>
          {this.state.submitted && this.state.success ? (
            <div style={{ textAlign: 'center' }}>
              <H1>Thank you for Contacting Us</H1>
              <BoldParagraph>
                We will get back to you soon.
            </BoldParagraph>
            </div>

          ) : (
              <FormComponent
                {...props}
                onSubmit={this.handleSubmit}
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                {children}
              </FormComponent>
            )
          }

        </div>
      )
    }
  }
  NetlifyForm.childContextTypes = {
    updateFormState: PropTypes.func,
    formState: PropTypes.object,
  }
  return NetlifyForm
}


// const SubmissionAlert = ({ success, error, requestAlertClose }) => (
//   <Alert isOpen={isNotNil(success) || isNotNil(error)} onRequestClose={requestAlertClose}>
//     <Alert.Toolbar>
//     </Alert.Toolbar>
//     <Alert.Title>{success ? 'Form Submitted Successfully' : 'An Error Ocurred'}</Alert.Title>
//     <Alert.Content>
//       <p>{success.message || error.message}</p>
//     </Alert.Content>
//     <Alert.Actions>
//       <Alert.Actions.Button onClick={requestAlertClose}>OK</Alert.Actions.Button>
//     </Alert.Actions>
//   </Alert>
// )


const Group = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;

    & input {
      flex: 1 1 auto;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
`

Form.Group = Group
Form.Input = Input
Form.TextArea = TextArea

export default Form