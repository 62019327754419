import React, { useState } from 'react'
import styled from '@emotion/styled'
import ReactPlayer from 'react-player'

function CarouselVideoPlayer({ url, onPlay, onPause }) {
  const [playing, setPlaying] = useState(true)
  const [lightMode, setLightMode] = useState(true)

  function handleOnPlay() {
    setPlaying(true)
    onPlay()
  }

  function handleOnPause() {
    setPlaying(false)
    onPause()
  }
  
  return (
    <VideoPlayer
      url={url}
      width="100%"
      height="100%"
      responsive="true"
      light={lightMode}
      playing={playing}
      onPlay={handleOnPlay}
      onPause={handleOnPause}
      onClick={() => setLightMode(false)}
    />
  )
}

const VideoPlayer = styled(ReactPlayer)`
  overflow: hidden;
  ${props =>
    props.responsive &&
    `
      position: absolute;
      top: 0;
      left: 0;
    `}
`

export default CarouselVideoPlayer
