import React from 'react'
import styled from '@emotion/styled'
import goosebumpsBg from './CF-Goosebump1.png'
import svgOverlay from './CF_Overlay_SVG.svg'
import goosebumpsDONLogo from './goosebumpsDON_logo.png'

function GoosebumpsDON () {
  return (
    <AngledWindow>
      {/* <GoosebumpsDONLogo src={goosebumpsDONLogo} /> */}
    </AngledWindow>
  )
}

const AngledWindow = styled.div`
  margin: 0 auto;
  width: auto;
  max-width: 1320px;
  height: 70vw;
  max-height: 900px;
  background-image: url(${goosebumpsBg});
  mask-image: url(${svgOverlay});
  background-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: contain;
  background-position: 25% bottom;
  display:flex;
`

// const GoosebumpsDONLogo = styled.img`
//   margin: auto;
// `

export default GoosebumpsDON
